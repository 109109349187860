.hero-video {
	@apply h-video-hero-height-mobile;
	@apply sm:h-video-hero-height-sm;
	@apply md:h-video-hero-height-md;
	@apply lg:h-video-hero-height;

	.follow-mouse-container {
		@apply w-preview-container-mobile;
		@apply h-preview-container-mobile;

		@screen sm {
			@apply w-follow-mouse-container-sm;
			@apply h-follow-mouse-container-sm;
		}

		@screen lg {
			@apply w-follow-mouse-container;
			@apply h-follow-mouse-container;
			@apply relative;
			@apply flex;
			@apply items-center;
			@apply justify-center;
		}
	}

	.preview-video-container {
		@apply w-preview-container-mobile;
		@apply h-preview-container-mobile;
		@apply sm:w-preview-container-sm;
		@apply sm:h-preview-container-sm;
		@apply lg:w-preview-container;
		@apply lg:h-preview-container;
		transition: opacity 0.3s $ease-in-out-quint;

		&:hover {
			@apply opacity-100;
		}

		&.follow-mouse-true {
			transition: opacity 0.3s $ease-in-out-quint, transform 0.4s $ease-out-sine;
		}
	}

	.preview-video {
		@apply w-preview-gif-mobile;
		@apply h-preview-gif-mobile;
		@apply sm:w-preview-gif-sm;
		@apply sm:h-preview-gif-sm;
		@apply lg:w-preview-gif;
		@apply lg:h-preview-gif;
		clip-path: circle(50% at 50% 50%);
	}

	.video-wrapper-help {
		@screen lg {
			height: calc(100% - 5rem);
		}
	}

	.video-wrapper {
		@screen lg {
			aspect-ratio: 16 / 9;
			@apply w-full;
			@apply h-auto;
		}

		@screen middlexl {
			width: 70%;
		}

		.video-volume,
		.video-play {
			svg {
				@apply transform;
				@apply scale-100;
				transition: transform 0.3s $ease-in-out-quint;
			}

			&:hover {
				svg {
					@apply scale-110;
				}
			}
		}
	}

	.video-overlay {
		@apply h-screen;
		@apply sm:h-video-hero-height-sm;
		@apply md:h-video-hero-height-md;
		@apply lg:h-screen;
	}

	.close-button {
		&:hover {
			.x-close {
				transform: rotate(180deg);
			}
		}

		.x-close {
			transition: transform 0.3s $ease-in-out-quint;
			@apply bg-transparent;
			@apply block;
			@apply h-0.5;
			@apply relative;
			@apply w-5;
			@apply ml-5;

			&::before,
			&::after {
				content: "";
				@apply block;
				@apply h-full;
				@apply absolute;
				@apply w-full;
			}

			&::before {
				@apply transform;
				@apply -rotate-45;
			}

			&::after {
				@apply transform;
				@apply rotate-45;
			}

			&:not(.steps) .navicon::before,
			&:not(.steps) .navicon::after {
				@apply top-0;
			}
		}
	}
}
