// Set default values that will be changed via animejs
.claim-section {
	.headline {
		@apply opacity-0;
	}

	.split-item {
		@apply overflow-hidden;
		@apply h-claim-line-height-mobile;
		clip-path: inset(0);

		@screen md {
			@apply h-claim-line-height;
		}

		span {
			transform: translateY(72px);
		}

		&.set-block {
			@apply block;
			@apply opacity-100;

			span {
				@apply block;
			}
		}
	}

	.text-button-container {
		clip-path: inset(0);
		@apply overflow-hidden;
		@apply opacity-0;
	}
}
