:root {
	--app-height: 100%;
}

.header-menu {
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	@apply w-screen;

	&.open-menu {
		// min-height: 100vh;
		// min-height: var(--app-height);
		transform: height 0.3s $ease-in-out-quint;
		height: 100vh;
		height: var(--app-height);
	}

	&.open-header {
		.header-bar {
			@apply bg-charcoal;
		}

		.logo-wrapper {
			@apply bg-charcoal;
		}

		.navicon {
			@apply bg-white;

			&::before,
			&::after {
				@apply bg-white;
			}
		}

		.logo {
			path,
			rect,
			circle,
			polygon,
			line {
				fill: theme("colors.white");
			}
		}
	}

	.menu-item {
		.menu-icon {
			path,
			rect,
			circle,
			polygon,
			line {
				transition: stroke 0.3s $ease-in-out-quint;
				stroke: theme("colors.white");
			}
		}

		&:hover {
			.menu-icon {
				path,
				rect,
				circle,
				polygon,
				line {
					stroke: theme("colors.charcoal");
				}
			}
		}

		&.set-bg-transparent {
			@apply bg-transparent;
		}
	}

	.lottie-animation-div {
		width: 5.31rem !important;
	}

	.mag-logo-wrapper {
		transition: opacity 0.6s ease-in-out;
	}

	.origin-text {
		&::before {
			@apply text-charcoal;
		}
	}

	.not-active {
		@apply opacity-40;
	}

	.header-bar {
		transition: background-color 0.3s $ease-out-quint;
	}

	.menu-item-link {
		.container {
			transition: opacity 0.3s $ease-out-quint;
		}
	}

	.menu-item {
		clip-path: inset(0 0 0 0);

		.menu-item-line {
			@apply h-0.5;
			@apply w-0;
			@apply block;
			@apply absolute;
			@apply bottom-0;
			transition: height 0.3s $ease-in-out-quint;
		}

		.origin-text {
			&::before {
				content: attr(data-content);
				@apply absolute;
				@apply h-full;
				@apply top-0;
				@apply flex;
				@apply items-center;
				clip-path: inset(100% 0 0 0);
				transition: clip-path 0.3s $ease-in-out-quint;
			}
		}

		&:hover {
			@apply cursor-pointer;

			.menu-item-line {
				@apply h-full;
			}

			.origin-text {
				&::before {
					clip-path: inset(0% 0 0 0);
				}
			}
		}
	}
	
	.mag-teaser-block-content {
		clip-path: inset(0 0 0 0);
		transition: all 0.5s ease-in-out;
		transform: translate(0, 120px);

		.hover-bg-div {
			@apply h-0;
			@apply w-0;
			@apply block;
			@apply absolute;
			@apply bottom-0;
			transition: height 0.3s $ease-in-out-quint;
		}

		.teaser-text {
			&::before {
				content: attr(data-content);
				@apply absolute;
				@apply h-full;
				@apply top-0;
				@apply flex;
				@apply items-center;
				clip-path: inset(100% 0 0 0);
				transition: clip-path 0.3s $ease-in-out-quint;
			}
		}

		&:hover {
			@apply text-charcoal;

			.mag-logo,
			.lottie-player {
				path,
				rect,
				circle {
					fill: theme("colors.charcoal") !important;
				}
			}

			.magazine-logo,
			.lottie-player {
				path {
					fill: theme("colors.charcoal") !important;
				}
			}

			@apply cursor-pointer;

			.hover-bg-div {
				@apply h-full w-full;
			}

			.teaser-text {
				&::before {
					clip-path: inset(0% 0 0 0);
				}
			}
		}
	}

	#hamburger {
		.navicon {
			transition: all 0.2s ease;

			&::before,
			&::after {
				content: "";
				@apply block;
				@apply h-full;
				@apply absolute;
				transition: all 0.2s ease;
				@apply w-full;
			}

			&::before {
				@apply top-2;
			}

			&::after {
				@apply -top-2;
			}
		}

		&:hover {
			.navicon {
				&::before {
					@apply -top-2;
				}

				&::after {
					@apply top-2;
				}
			}
		}

		&.close {
			.navicon {
				@apply bg-transparent;

				&::before {
					@apply transform;
					@apply -rotate-45;
				}

				&::after {
					@apply transform;
					@apply rotate-45;
				}
			}

			&:not(.steps) .navicon::before,
			&:not(.steps) .navicon::after {
				@apply top-0;
			}

			&:hover {
				.navicon {
					@apply transform;
					@apply rotate-180;
					@apply origin-center;
					transition: transform 0.3s $ease-in-out-quint;
				}
			}
		}
	}

	#menu {
		min-height: calc(var(--app-height) - 80px);

		&.open {
			@apply flex;
			@apply flex-col;
			@apply w-full;
			@apply h-auto;
			@apply z-20;

			.menu-items {
				@apply flex-col;
				@apply h-auto;
			}
		}

		.magazine-logo {
			path {
				fill: theme("colors.mag-yellow");
			}
		}
	}
}
