.text-section,
.headline-text-section {
	.content-text-section {
		transition: max-height 0.3s $ease-in-out-quint;
		@screen md {
			max-height: none;
		}
	}

	.chevron-up {
		@apply origin-center;
		@apply transform;
		@apply rotate-0;
		@apply cursor-pointer;
		transition: transform 0.3s $ease-in-out-quint;

		&.collapsed {
			@apply rotate-180;
		}
	}

	.rich-text {
		h3 {
			@apply text-h3-mobile;
			@apply font-esrebond-regular;
			@apply mb-8;

			@screen md {
				@apply md:text-h3;
			}
		}

		p {
			@apply text-base;
			@apply font-silka-regular;
		}

		a {
			@apply underline;
		}
	}
}
