:root {
	--app-height-form: 100%;
}

.contact-page {
	.vertical-container {
		@apply relative;
		@apply inline-flex;
		@apply justify-end;
		@apply top-0;
		@apply items-center;
		transform: rotate(-90deg) translateX(-100%);
		transform-origin: left;
	}
}

footer {
	.text-animation-line {
		@apply opacity-0;
		@apply mb-6;
		@apply text-charcoal;
	}

	.form-overlay {
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
		height: 100vh;
		height: var(--app-height-form);
	}

	.form {
		@apply text-heading-dark;

		h6 {
			@apply font-esrebond-regular;
			@apply text-overline-mobile;
			@apply md:text-overline;
			@apply text-heading-dark;
		}

		h4 {
			@apply font-esrebond-regular;
			@apply text-h4-mobile;
			@apply md:text-h4;
			@apply mb-8;
			@apply text-heading-dark;
		}

		// Button
		.fui-submit,
		.fui-btn {
			background-color: transparent;
			@apply border-none;
			@apply uppercase;
			@apply font-esrebond-medium;
			@apply text-button-jobs;
			@apply text-heading-dark;
			@apply w-full;
			@apply flex;
			@apply items-center;
			@apply mt-8;
			@apply px-0;

			&::after {
				content: "";
				background-image: url("../../images/ic_arrow_black.svg");
				background-repeat: no-repeat;
				height: 54px;
				width: 54px;
				@apply block;
				@apply ml-6;
			}
		}

		// Checkbox
		.fui-checkbox {
			@apply mt-6;

			.fui-checkbox-label {
				@apply font-esrebond-regular;
				@apply text-overline-mobile;
				@apply md:text-overline;
				@apply text-body-1;

				a {
					@apply underline;
				}

				&::before {
					@apply border-body-1;
					@apply bg-transparent;
				}
			}

			input:focus + label::before {
				@apply shadow-none;
			}

			input:checked + label::before {
				@apply border-body-1;
				@apply bg-body-1;
			}
		}

		// Textfields
		.fui-input {
			@apply md:w-3/4;
			@apply mt-4;
			@apply p-4;
			@apply bg-transparent;
			@apply border-t-0;
			@apply border-l-0;
			@apply border-r-0;
			@apply border-b-3;
			@apply border-black;
			@apply rounded-none;
			@apply font-esrebond-regular;
			@apply text-form-placeholder-mobile;
			@apply md:text-form-placeholder;
			@apply text-heading-dark;

			&::placeholder {
				@apply text-body-2;
				@apply font-esrebond-regular;
				@apply text-form-placeholder-mobile;
				@apply md:text-form-placeholder;
			}
		}

		.fui-error-message,
		.fui-alert-error {
			@apply font-esrebond-regular;
			@apply text-error;
		}

		.fui-alert-success {
			@apply hidden;
		}

		.fui-alert-error {
			@apply bg-transparent;
			@apply p-0;
		}

		.fui-input:-internal-autofill-selected {
			background-image: none !important;
			background-color: #eae7e5 !important;
			color: #121212 !important;
		}
	}

	.close-icon-div {
		.icon-close {
			transition: transform 0.3s $ease-in-out-quint;
			@apply transform;
			@apply origin-center;
		}

		&:hover {
			.icon-close {
				@apply rotate-180;
			}
		}
	}
}
