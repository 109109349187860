.banner-magazine {
	transition: background-color 0.4s ease-in-out;

	path,
	button,
	p {
		transition: all 0.4s ease-in-out;
	}

	button svg {
		transition: all 0.4s ease-in-out;
	}

	&:hover {
		@apply bg-mag-yellow;
		@apply text-charcoal;
		
		p {
			@apply text-charcoal;
		}

		.logo,
		svg {
			path {
				fill: #111;
			}
		}

		button {
			@apply text-charcoal;

			svg {
				transform: scale(1.25);
			}
		}
	}

	.logo {
		width: 9.5rem;
	}
}
