.media-case-studies {
	.video-volume,
	.video-play {
		svg {
			@apply transform;
			@apply scale-100;
			transition: transform 0.3s $ease-in-out-quint;
		}

		&:hover {
			svg {
				@apply scale-110;
			}
		}
	}

	picture {
		// img {
		@apply opacity-0;
		@apply translate-y-20;
		// }
		img {
			transition: transform 0.5s $ease-in-out-quint;
		}
	}

	.video-container {
		@apply opacity-0;
		@apply translate-y-20;
	}
}
