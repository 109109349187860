.client-shuffle {
	.text {
		@apply relative;
		@apply inline-block;
		@apply overflow-hidden;
		@apply w-auto;

		&.text-no-link {
			// @apply opacity-30;
		}

		a {
			@apply relative;
			@apply block;

			.text-origin {
				@apply opacity-30;
				@apply block;
			}

			.text-hover {
				@apply absolute;
				@apply top-0;
				@apply left-0;
				clip-path: inset(100% 0 0 0);
				transition: clip-path 0.8s $ease-out-quint;
			}

			&:hover {
				.text-hover {
					clip-path: inset(0);
				}
			}
		}
	}

	button#refreshClientsBtn {
		@apply rotate-0;

		&:hover {
			.btn-outlined {
				transition: opacity 0.3s $ease-in-out-quint;
				@apply opacity-0;
			}

			.btn-filled {
				transition: opacity 0.3s $ease-in-out-quint;
				@apply opacity-100;
			}
		}
	}
}
