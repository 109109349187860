.department-section {
	.member {
		.member-image {
			@apply absolute;
			@apply overflow-auto;
			@apply w-40;
			@apply h-80;
			@apply z-9;

			@media (min-width: theme("screens.sm")) {
				width: 16rem;
				height: 25rem;
			}
			@media (min-width: theme("screens.md")) {
				width: 18rem;
				height: 25rem;
			}
			@media (min-width: theme("screens.xl")) {
				width: 22rem;
				height: 30rem;
			}
			@media (min-width: theme("screens.2xl")) {
				width: 30rem;
				height: 45rem;
			}
		}

		.member-name {
			@apply z-10;

			@screen lg {
				.span-light {
					transform: translateY(0);
					transition: transform 0.3s $ease-in-out-quint;
				}
	
				.span-dark {
					transform: translateY(0);
					transition: transform 0.3s $ease-in-out-quint;
				}
			}

			&:hover {
				.span-light {
					@apply opacity-100;
				}

				@screen lg {
					@apply z-9;
					@apply relative;
	
					.span-light {
						transform: translateY(-100%);
						@apply opacity-20;
					}
	
					.span-dark {
						transform: translateY(-100%);
					}
				}
			}
		}
	}
}
