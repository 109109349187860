// Background colors and font colors
.theming-light {
	@apply bg-white;
}

.theming-sand {
	@apply bg-sand;
}

.theming-dark {
	@apply bg-charcoal;
}

// Arrow Button
.theming-dark {
	.arrow {
		@apply text-white;

		svg path {
			fill: theme("colors.white");
		}
	}
}

//Text Section
.theming-dark {
	.collapsible {
		svg path {
			fill: theme("colors.white");
		}
	}
}

.theming-light,
.theming-sand {
	.collapsible {
		svg path {
			fill: theme("colors.charcoal");
		}
	}
}

//Job details page
.job-details-page {
	&.theming-dark {
		svg {
			path,
			rect,
			circle,
			polygon,
			line {
				stroke: theme("colors.white");
			}

			path {
				fill: theme("colors.white");
			}
		}

		.job-lists {
			.rich-text {
				ul {
					li {
						background: url("/src/images/dash-list-white.gif") no-repeat left 15px;
					}
				}
			}
		}
	}

	&.theming-light,
	&.theming-sand {
		svg {
			path,
			rect,
			circle,
			polygon,
			line {
				stroke: theme("colors.charcoal");
			}

			path {
				fill: theme("colors.charcoal");
			}
		}

		.job-lists {
			.rich-text {
				ul {
					li {
						background: url("/src/images/dash-list-black.gif") no-repeat left 15px;
					}
				}
			}
		}
	}
}

// Partner Slider
.theming-light,
.theming-sand {
	@apply text-charcoal;

	.partner-card {
		@apply text-charcoal;
		transition: border-color 0.3s $ease-in-out-quint;

		svg {
			transition: opacity 0.3s $ease-in-out-quint;

			path {
				fill: theme("colors.charcoal");
			}
		}

		&:hover {
			transition: border-color 0.3s;
			@apply border-charcoal;

			svg {
				@apply opacity-100;
			}
		}
	}
}

.theming-dark {
	@apply bg-charcoal;

	.partner-card {
		@apply text-white;
		@apply border-charcoal;
		transition: border-color 0.3s $ease-in-out-quint;

		svg {
			transition: opacity 0.3s $ease-in-out-quint;

			path {
				fill: theme("colors.white");
			}
		}

		&:hover {
			transition: border-color 0.3s;
			@apply border-white;

			svg {
				@apply opacity-100;
			}
		}
	}
}

// Numbered Slider
.theming-light,
.theming-sand {
	@apply text-charcoal;

	&.numbered-slider-section {
		@apply text-charcoal;

		.swiper-button {
			transition: background-color 0.3s ease;

			svg {
				path {
					fill: theme("colors.charcoal");
				}
			}

			&.swiper-button-disabled {
				@apply opacity-50;
			}
		}

		.swiper-prev-div {
			svg {
				transform: scaleX(-1);

				path {
					fill: theme("colors.charcoal");
				}
			}
		}
	}
}

.theming-dark {
	&.numbered-slider-section {
		@apply text-white;

		.swiper-button {
			transition: background-color 0.3s ease;

			&.swiper-button-disabled {
				@apply opacity-50;
			}
		}

		.swiper-prev-div {
			svg {
				transform: scaleX(-1);
			}
		}
	}
}

// Job Slider
.theming-light,
.theming-sand {
	.job-slider {
		.job-card {
			svg {
				path,
				rect,
				circle,
				polygon {
					stroke: theme("colors.charcoal");
				}

				path {
					fill: theme("colors.charcoal");
				}
			}

			&.unsolicited {
				@apply border-charcoal;
			}

			&:hover {
				@apply border-charcoal;
			}
		}

		.swiper-button {
			&:hover {
				@apply bg-charcoal;
			}

			svg path {
				fill: theme("colors.charcoal");
			}
		}
	}
}

.theming-light {
	.job-slider {
		.swiper-button {
			&:hover {
				svg path {
					fill: theme("colors.white");
				}
			}
		}
	}
}

.theming-sand {
	.job-slider {
		.swiper-button {
			&:hover {
				svg path {
					fill: theme("colors.sand");
				}
			}
		}
	}
}

.theming-dark {
	.job-slider {
		.job-card {
			svg {
				path,
				rect,
				circle,
				polygon {
					stroke: theme("colors.white");
				}

				path {
					fill: theme("colors.white");
				}
			}

			&.unsolicited {
				@apply border-white;
			}

			&:hover {
				@apply border-white;
			}
		}

		.swiper-button {
			svg path {
				fill: theme("colors.white");
			}
		}
	}
}

// Banner
.theming-light,
.theming-sand {
	@apply text-charcoal;

	.word-container {
		@apply border-charcoal;
	}
}

.theming-dark {
	@apply text-white;

	.word-container {
		@apply border-white;
	}
}

// Client Shuffle
.theming-dark {
	&.client-shuffle {
		.btn-outlined {
			path {
				fill: theme("colors.white");
			}

			rect {
				stroke: theme("colors.white");
			}
		}

		.btn-filled {
			path {
				fill: theme("colors.white");
			}
		}
	}
}

// Best Ofs
.theming-light,
.theming-sand {
	.best-ofs-mobile {
		.line {
			@apply border-charcoal;
		}
	}

	.best-ofs-desktop {
		.project-card-border {
			@apply border-charcoal;
		}
	}
}

// Hero Video
.theming-dark {
	&.hero-video {
		.icons-container {
			svg {
				path,
				rect,
				circle,
				polygon {
					stroke: theme("colors.white");
				}
			}
		}

		.preview-gif-container {
			@apply bg-charcoal;
		}

		.video-overlay {
			@apply bg-charcoal;
			@apply text-white;
		}

		.logo {
			path,
			rect,
			circle {
				fill: theme("colors.white");
			}
		}

		.x-close {
			&::before,
			&::after {
				@apply bg-white;
			}
		}
	}
}

.theming-light,
.theming-sand {
	&.hero-video {
		.icons-container {
			svg {
				path,
				rect,
				circle,
				polygon {
					stroke: theme("colors.charcoal");
				}
			}
		}

		.video-overlay {
			@apply text-charcoal;
		}

		.logo {
			path,
			rect,
			circle {
				fill: theme("colors.charcoal");
			}
		}

		.x-close {
			&::before,
			&::after {
				@apply bg-charcoal;
			}
		}
	}
}

.theming-light {
	&.hero-video {
		.preview-gif-container {
			@apply bg-white;
		}

		.video-overlay {
			@apply bg-white;
		}
	}
}

.theming-sand {
	&.hero-video {
		.preview-gif-container {
			@apply bg-sand;
		}

		.video-overlay {
			@apply bg-sand;
		}
	}
}

// body
body {
	&.body-theming-dark {
		@apply bg-charcoal;
	}

	&.body-theming-light {
		@apply bg-white;
	}
	
	&.body-theming-sand {
		@apply bg-sand;
	}
}

// header
header {
	&.theming-dark {
		@apply bg-transparent;

		.header-bar {
			@apply bg-charcoal;
		}

		.logo-wrapper {
			@apply bg-charcoal;
		}

		.navicon {
			@apply bg-white;

			&::before,
			&::after {
				@apply bg-white;
			}
		}

		.logo,
		.mag-logo,
		.lottie-player {
			path,
			rect,
			circle {
				fill: theme("colors.white");
			}
		}
	}

	&.theming-light,
	&.theming-sand {
		@apply bg-transparent;

		.navicon {
			@apply bg-charcoal;

			&::before,
			&::after {
				@apply bg-charcoal;
			}
		}

		.logo,
		.mag-logo,
		.lottie-player {
			path,
			rect,
			circle {
				fill: theme("colors.charcoal");
			}
		}
	}

	&.theming-light {
		.header-bar {
			@apply bg-white;
		}

		.logo-wrapper {
			@apply bg-white;
		}
	}

	&.theming-sand {
		.header-bar {
			@apply bg-sand;
		}

		.logo-wrapper {
			@apply bg-sand;
		}
	}
}

// project details page
.project-details-page {
	&.theming-sand,
	&.theming-light {
		.text-mask {
			@apply text-white;

			svg {
				path {
					fill: theme("colors.white");
				}
			}
		}

		.btn-color {
			@apply bg-charcoal;
		}

		.origin-text {
			svg {
				path {
					fill: theme("colors.charcoal");
				}
			}
		}

		.back-btn-container {
			svg {
				path {
					fill: theme("colors.charcoal");
				}
			}
		}

		.button-next-project {
			&.ios-hover {
				&:hover {
					svg {
						@apply bg-black;
						@apply rounded-radius-next-button;
	
						path {
							fill: theme("colors.white");
						}
					}
				}
			}
		}
	}

	&.theming-dark {
		.text-mask {
			@apply text-charcoal;

			svg {
				path {
					fill: theme("colors.charcoal");
				}
			}
		}

		.btn-color {
			@apply bg-white;
		}

		.origin-text {
			svg {
				path {
					fill: theme("colors.white");
				}
			}
		}

		.back-btn-container {
			svg {
				path {
					fill: theme("colors.white");
				}
			}
		}

		.button-next-project {
			&.ios-hover {
				&:hover {
					svg {
						@apply bg-white;
						@apply rounded-radius-next-button;
	
						path {
							fill: theme("colors.black");
						}
					}
				}
			}
		}
	}
}

// Logo Slider
.theming-light,
.theming-sand {
	&.logo-slider-section {
		.swiper-button {
			svg path {
				fill: theme("colors.charcoal");
			}
		}
	}
}

.theming-dark {
	&.logo-slider-section {
		.swiper-button {
			svg path {
				fill: theme("colors.white");
			}
		}
	}
}
