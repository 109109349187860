.job-page {
	.jobs {
		min-height: calc(100vh - 5rem);

		@screen md {
			min-height: auto;
		}
	}

	.job-card {
		transition: background-color 0.2s $ease-in-out-quint 0s;

		svg {
			path,
			rect,
			circle,
			polygon {
				transition: stroke 0.2s $ease-in-out-quint 0s;
				stroke: theme("colors.charcoal");
			}

			path {
				fill: theme("colors.charcoal");
			}
		}

		.link,
		.title,
		.job-location {
			transition: color 0.2s $ease-in-out-quint 0s;
		}

		&.job-offer {
			@screen md {
				&:hover {
					@apply bg-charcoal;
					@apply relative;
	
					svg {
						path,
						rect,
						circle,
						polygon {
							stroke: theme("colors.white");
						}

						path {
							fill: theme("colors.white");
						}
					}
	
					.link,
					.title,
					.job-location {
						@apply text-white;
					}
				}
			}
		}
	}
}

