.contact-page {
	.answer {
		transition: color 0.3s $ease-in-out-quint;

		&:hover {
			@apply text-white;
		}

		&.selected {
			@apply text-white;
		}
	}

	.text-input {
		textarea {
			@apply border-white;

			&:focus-visible {
				@apply outline-none;
				@apply bg-white;
				@apply bg-opacity-5;
			}
		}
	}

	.single-choice-wrapper0,
	.text-input-wrapper0 {
		@apply opacity-0;
	}
}
