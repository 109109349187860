.partner-section {
	.swiper-slide {
		&.half-width {
			@screen md {
				width: 50% !important;
			}
		}
	}

	.swiper-wrapper {
		&.mobile-no-swiper {
			@media (max-width: 768px) {
				@apply flex flex-col w-full;

				.swiper-slide {
					@apply w-full mb-16 #{!important};

					.partner-card {
						@apply px-4;
					}
				}

				&:last-child {
					@apply mb-0 #{!important};
				}
			}
		}
	}
}
