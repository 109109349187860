.logo-slider-section {
	.logo-image-container {
		transition: opacity 0.3s $ease-in-out-quint, filter 0.3s $ease-in-out-quint;

		&:hover {
			@apply grayscale-0;
			@apply opacity-100;
		}
	}

	.swiper-button {
		&.swiper-button-disabled {
			@apply opacity-50;
		}
	}

	.swiper-prev-div-logo-slider {
		svg {
			transform: scaleX(-1);
		}
	}
}
