.project-page {
	.case-study-link {
		@apply opacity-50;

		&:hover {
			@apply z-50;
			@apply relative;
			@apply opacity-100;
		}
	}

	@media (min-width: theme("screens.md")) {
		.project-categories {
			.active {
				@apply underline;
			}

			.project-category-link {
				@apply inline-flex;
				@apply relative;
				@apply overflow-hidden;

				&::after {
					content: "";
					@apply absolute;
					@apply left-0;
					@apply w-0;
					@apply bg-charcoal;
					@apply h-0;
					@apply -bottom-px;
					transition: width 0.3s $ease-in-out-quint;
				}

				&:hover:not(.active) {
					&::after {
						@apply left-0;
						@apply right-auto;
						@apply w-full;
						@apply h-0.5;
					}
				}
			}
		}

		.case-study {
			.case-study-image {
				@apply opacity-0;
				transition: opacity 0.3s $ease-in-out-quint;
			}

			.case-study-link:hover + .case-study-image {
				@apply opacity-100;
				transition: opacity 0.3s $ease-in-out-quint;
			}
		}
	}

	.data-content {
		/* stylelint-disable */
		height: calc(100vh - 5rem - 4.5rem); // screen height - header height - headline height
		/* stylelint-enable */
		@apply overflow-hidden;
		@apply bg-sand;
		@apply w-0;
		transform: translateX(100%);
		transition: transform 0.3s $ease-in-out-quint;
	}

	@media (min-width: theme("screens.md")) {
		.data-content {
			/* stylelint-disable */
			height: calc(
				100vh - 5rem - 2.5rem - 4.5rem - 4rem
			); // screen height - header height - section padding - headline height - row padding
			/* stylelint-enable */
			@apply w-9/12;
			transform: translateX(0%);
		}
	}

	.project-categories {
		transform: translateX(0%);
		transition: transform 0.3s $ease-in-out-quint;
	}

	.active-content {
		@apply w-full;
		transform: translateX(0%);
		transition: transform 0.3s $ease-in-out-quint;
	}

	.project-categories-left {
		transform: translateX(-100%);
		transition: transform 0.3s $ease-in-out-quint;
		@apply h-0;
	}

	.clients {
		-webkit-overflow-scrolling: touch;
		@apply overflow-hidden;
		@apply h-0;

		&.is-visible {
			@apply overflow-y-auto;
			@apply overflow-x-hidden;
			@apply h-auto;
			@apply absolute;
			@apply w-full;
			@apply top-14;
			@apply bottom-20;

			.client-headline {
				clip-path: inset(100% 0 0 0);
			}
		}
	}

	@media (min-width: theme("screens.md")) {
		.clients {
			@apply overflow-y-auto;
			@apply overflow-x-hidden;
			@apply h-auto;
			@apply absolute;
			@apply w-full;
			@apply top-0;
			@apply bottom-0;
		}
	}
}
