.job-details-page {
	.rich-text {
		h3 {
			@apply text-h3-mobile;
			@apply font-esrebond-regular;
			@apply mb-8;

			@screen md {
				@apply text-h3-mobile;
			}
		}

		ul {
			li {
				@apply mb-4;
				@apply text-base;
				@apply font-silka-regular;
				@apply list-none;
				@apply pt-1;
				@apply pl-6;
			}
		}
	}

	.logo-color-border {
		background: linear-gradient(to right, #ea865b -18.18%, #91b7fa 49.96%, #cca6eb 116.7%);
	}

	.black-border {
		@apply border-charcoal;
	}

	.sticky-arrow-button {
		transition: opacity 0.3s $ease-in-out-quint;
	}
}
