#cookie-banner {
	.cookie-notice {
		@apply rounded-none;
		@apply w-full;
		@apply max-w-full;
		@apply left-0;
		@apply right-0;
		@apply bottom-0;
		@apply bg-white;
		box-shadow: 0 2px 4px rgba(143, 143, 143, 0.1), 0 4px 15px rgba(110, 128, 146, 0.15);

		.cn-body {
			@apply flex;
			@apply py-6;
			@apply px-4;
			@apply w-full;
			@apply flex-col;

			@screen lg {
				@apply flex-row;
				@apply py-6;
				@apply px-10;
			}

			@screen xl {
				@apply items-center;
				@apply justify-between;
			}

			p {
				@apply text-base;
				@apply font-silka-regular;
				@apply w-full;
				@apply max-w-full;
				@apply mb-10;
				@apply mt-0;

				@screen lg {
					@apply w-5/12;
					@apply m-0;
					@apply my-auto;
				}

				span {
					@apply max-w-full;
					@apply text-body-1;

					a {
						@apply text-charcoal;
						@apply font-silka-semibold;

						&:hover {
							@apply underline;
						}
					}
				}
			}

			.cn-ok {
				@apply my-auto;
				@apply justify-center;

				@screen md {
					@apply justify-end;
				}

				@screen lg {
					@apply w-7/12;
				}

				.cn-learn-more {
					@apply text-button-cookie;
					@apply font-esrebond-regular;
					@apply text-charcoal;
					@apply font-medium;
					@apply mb-2;
					@apply mt-0;
					@apply uppercase;
					transition: color 0.3s $ease-in-out-quint;
					@apply text-center;
					@apply py-2;
					@apply w-auto;

					@screen md {
						@apply text-right;
						@apply mr-4;
						@apply mb-0;
						@apply py-0;
					}

					&:hover {
						@apply underline;
					}
				}
			}
		}
	}

	.cookie-modal {
		@apply flex;
		@apply justify-center;
		@apply items-baseline;

		.cm-modal {
			@apply bg-white;
			width: calc(100% - 2rem);

			@screen md {
				@apply w-auto;
			}

			p {
				@apply my-3;
			}

			p,
			label span {
				@apply text-base;
				@apply text-charcoal;
			}

			.cm-header {
				button {
					transition: transform 0.3s $ease-in-out-quint;

					&:hover {
						transform: rotate(180deg);
					}

					svg {
						stroke: theme("colors.charcoal");
					}
				}

				@apply px-4;
				@apply py-6;
				@apply border-lightGray;
				@apply border-b;

				@screen md {
					@apply p-6;
				}

				.title {
					@apply text-codGray;
					@apply text-h4-mobile;
					@apply font-esrebond-regular;

					@screen md {
						@apply text-h4;
					}
				}

				p {
					@apply mb-0;
					@apply mt-4;

					span {
						@apply font-silka-regular;
						@apply text-body-1;

						a {
							@apply text-charcoal;
							@apply font-silka-semibold;

							&:hover {
								@apply underline;
							}
						}
					}
				}
			}

			.cm-body {
				@apply p-4;

				@screen md {
					@apply px-6;
					@apply py-4;
				}

				.cm-list-input:checked + .cm-list-label .slider {
					@apply opacity-100;
					@apply bg-charcoal;

					&::before {
						@apply left-1.5;
						@apply bg-white;
					}
				}

				.cm-list-input + .cm-list-label .slider {
					@apply opacity-100;
					@apply bg-stone;
					@apply shadow-none;
					@apply h-6;
					@apply w-12;

					&::before {
						@apply bg-opacity-40;
						@apply top-0.5;
						@apply left-0.5;
					}
				}

				.cm-purpose {
					@apply py-2;
					@apply mt-0;

					@screen md {
						@apply py-4;
					}

					label {
						.cm-list-title {
							@apply text-codGray;
							@apply font-silka-semibold;
							@apply text-base;
						}

						.cm-required {
							@apply text-codGray;
							@apply font-silka-regular;
							@apply text-caption;
							@apply opacity-40;
							@apply pl-1;

							@screen md {
								@apply text-base;
							}
						}
					}

					.cm-services {
						a {
							@apply text-base-small;
							@apply text-charcoal;
							@apply font-silka-regular;
						}
					}

					&:last-child {
						@apply my-0;
					}

					.purposes {
						@apply m-0;
					}

					&.cm-toggle-all {
						@apply border-0;

						.cm-list-description {
							@apply m-0;
						}

						input.only-required + .cm-list-label .slider::before {
							@apply -left-1.6;
						}

						.cm-switch {
							.slider {
								&.active {
									&::before {
										@apply left-1.5;
									}
								}
							}
						}
					}
				}
			}

			.cm-footer {
				@apply border-lightGray;
				@apply p-6;

				.cm-footer-buttons {
					@apply flex-col;

					@screen md {
						@apply flex-row;
					}

					button {
						@apply mx-0;
						@apply px-4;
						@apply w-full;

						@screen md {
							@apply mx-auto;
							@apply w-auto;
							@apply mx-auto;
						}

						&.cm-btn-accept {
							@apply text-charcoal;
							@apply bg-transparent;
							@apply border-2;
							@apply border-charcoal;
							@apply border-solid;
							@apply mb-2;
							transition: color 0.3s $ease-in-out-quint, background-color 0.3s $ease-in-out-quint;

							@screen md {
								@apply mb-0;
							}

							&:hover {
								@apply text-white;
								@apply bg-charcoal;
								@apply opacity-100;
								transition: color 0.3s $ease-in-out-quint, background-color 0.3s $ease-in-out-quint;
							}
						}

						&.cn-decline {
							@apply border-0;
							@apply px-0;

							&:hover {
								@apply text-charcoal;
								@apply bg-white;
								@apply underline;
								transition: color 0.3s $ease-in-out-quint, background-color 0.3s $ease-in-out-quint;
							}
						}
					}
				}
			}
		}
	}

	button {
		@apply text-button-cookie;
		@apply font-esrebond-regular;
		@apply font-medium;
		@apply uppercase;
	}

	.cn-decline {
		@apply text-charcoal;
		@apply bg-transparent;
		@apply border-2;
		@apply border-solid;
		@apply border-charcoal;
		transition: color 0.3s $ease-in-out-quint, border-color 0.3s $ease-in-out-quint;
		@apply py-4;
		@apply mt-0;
		@apply mb-2;
		@apply mr-0;
		@apply text-center;
		@apply w-full;

		@screen md {
			@apply w-auto;
			@apply py-4;
			@apply px-8;
			@apply mb-0;
			@apply mr-4;
		}

		&:hover {
			@apply text-white;
			@apply bg-charcoal;
			transition: color 0.3s $ease-in-out-quint, background-color 0.3s $ease-in-out-quint;
		}
	}

	.cm-btn-success {
		@apply text-white;
		@apply bg-charcoal;
		@apply m-0;
		@apply py-4;
		@apply text-center;
		@apply w-full;
		@apply border-2;
		@apply border-solid;
		@apply border-charcoal;
		transition: color 0.3s $ease-in-out-quint, border-color 0.3s $ease-in-out-quint;

		@screen md {
			@apply w-auto;
			@apply py-4;
			@apply px-8;
		}

		&:hover {
			@apply opacity-80;
			transition: opacity 0.3s $ease-in-out-quint;
		}
	}

	.cm-powered-by {
		@apply hidden;
	}
}
