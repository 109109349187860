.best-ofs {
	.image-overlay {
		transition: opacity 0.5s $ease-in-out-sine;
	}

	.project-slide {
		&:hover {
			.image-overlay {
				@apply opacity-100;
			}
		}
	}
}
