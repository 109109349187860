.numbered-slider-section {
	.numbered-slider {
		.rich-text {
			p {
				@apply text-base;
				@apply font-silka-regular;
				@apply pb-3;
			}

			a {
				@apply underline;
			}
		}
	}

	.swiper-button {
		&.swiper-button-disabled {
			@apply opacity-50;
		}
	}

	.swiper-prev-div-numbered-slider {
		svg {
			transform: scaleX(-1);
		}
	}
}
