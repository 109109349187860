.error-page {
	@apply min-h-screen;

	svg {
		path,
		rect,
		circle,
		polygon,
		line {
			stroke: theme("colors.white");
		}
	}

	.vertical-container {
		@apply relative;
		@apply inline-flex;
		@apply justify-end;
		@apply items-center;
		@apply top-0;
		transform: rotate(-90deg) translateX(-100%);
		transform-origin: left;
	}
}
