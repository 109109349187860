.project-details-page {
	@screen lg {
		.col {
			@apply px-11;
		}
	}

	@screen xl {
		.col {
			@apply px-20;
		}
	}

	@screen 2xl {
		.col {
			@apply px-28;
		}
	}

	.page-content-wrapper {
		max-width: 1440px;
	}

	.project-categories {
		.project-category-container {
			.project-category-headline {
				&::after {
					content: "";
					@apply h-0.5;
					@apply w-0;
					@apply bg-charcoal;
				}

				&.animate-headline {
					&::after {
						transition: width 0.3s $ease-in-out-quint;
					}
				}

				&.active {
					&::after {
						@apply w-full;
					}
				}

				&:hover {
					&::after {
						@apply w-full;
					}
				}
			}

			.project-clients {
				&.animate-clients {
					transition: all 0.25s ease-in-out;
				}
			}

			.client-link {
				span {
					@apply transform;
					@apply translate-x-0;
				}

				&:hover {
					span {
						@apply translate-x-4;
					}
				}

				&.active {
					span {
						@apply translate-x-4;
						@apply font-esrebond-medium;
					}
				}

				&.animate-client-link {
					span {
						transition: transform 0.3s $ease-in-out-quint;
					}
				}
			}
		}
	}

	.headline-wrapper {
		@apply overflow-hidden;
		clip-path: inset(0 0 0 0);

		h1 {
			@apply translate-y-12;
		}
	}

	.claim-wrapper {
		@apply overflow-hidden;
		clip-path: inset(0 0 0 0);

		.project-claim {
			@apply translate-y-20;
			@apply opacity-0;
		}
	}

	.description {
		@apply translate-y-12;
		@apply opacity-0;
	}

	.data-wrapper {
		div {
			@apply opacity-0;
			@apply translate-x-20;
		}
	}

	.button-next-project {
		text-orientation: mixed;
		writing-mode: vertical-rl;
		transition: opacity 0.3s $ease-in-out-quint;

		&:hover {
			.btn-color {
				@apply opacity-100;
			}

			.text-mask {
				transition: opacity 0.3s $ease-in-out-quint;
				@apply opacity-100;
			}
		}

		&.ios-hover {
			@apply select-none;
			
			&:focus-visible {
				@apply outline-none;
			}

			&:hover {
				.btn-color,
				.text-mask {
					@apply opacity-0;
				}
			}
		}
	}

	.button-container {
		@apply transform;
		@apply lg:translate-y-32;
	}

	.teaser-container {
		@apply transform;
		@apply lg:translate-y-32;
	}
}
