.legal-page {
	.rich-text-legal-pages {
		p {
			@apply text-base;
			@apply font-silka-regular;
			@apply mb-2;

			a {
				hyphens: auto;
			}
		}

		a {
			@apply underline;
		}
		
		h6 {
			@apply text-base;
			@apply font-silka-semibold;
			@apply mt-12;
			@apply mb-4;
		}

		li {
			@apply text-base;
			@apply font-silka-regular;
			@apply list-disc;
			@apply list-inside;
		}
	}
}
