body {
	min-height: 100%;

	&.no-callout {
		/* stylelint-disable */
		-webkit-touch-callout: none !important;
		/* stylelint-enable */

		a,
		img {
			user-select: none !important;
		}
	}
}

* { 
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
}

// set cursor to default, because magicmouse removes cursor
.page-content-wrapper,
section,
footer,
header,
div.swiper-wrapper,
.error-page,
.project-details-page,
#cookie-banner,
.legal-page {
	@apply cursor-auto;
}

body {
	.grab-cursor {
		section,
		footer,
		header,
		div,
		span,
		a {
			cursor: grab !important;
		}
	}
}

// Magic Mouse Overwrittes
body #magicPointer {
	@apply cursor-default;
	@apply h-0;
	@apply w-0;
}

body #magicPointer.pointer-overlay {
	cursor: none;
	@apply h-20;
	@apply w-20;
	@apply shadow-none;
	@apply z-50;
}

body .magic-hover {
	cursor: none !important;

	section,
	footer,
	header,
	div,
	span,
	a {
		cursor: none !important;
	}
}

.content {
	// min-height: 100vh;
	min-height: calc(100vh - 5rem);
}

// helping class for swiper with cssMode
.slider-container {
	@screen sm {
		@apply container;
	}
	
	.row {
		@apply m-0;
		@apply md:m-auto;
	}
	
	.col {
		@apply p-0;
		@apply md:px-7;
	}
}

// global swiper flickering fix
// .swiper-slide {
// 	/* stylelint-disable */
// 	-webkit-transform: translate3d(0, 0, 0);
// 	/* stylelint-enable */
// }

.swiper-slide {
	/* stylelint-disable */
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
	/* stylelint-enable */
}

.swiper-wrapper {
	/* stylelint-disable */
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translateZ(0);
	/* stylelint-enable */
}

// Image Lazy Load Blurred Preview
img.ls-blur-up-img {
	display: none !important;
}

img.lazyloading.blur-lazy,
img.lazyload.blur-lazy {
	filter: blur(15px);
	transform: translateZ(0);
	transition: filter 0.35s ease-out;
	overflow: hidden;
}

img.lazyloaded.blur-lazy {
	transition: filter 0.35s ease-out;
	transform: translateZ(0);
	filter: blur(0);
}

img.lazyload:not([src]) {
	visibility: hidden;
}

// Set max width depending on design
.container {
	@apply mx-auto;
	@apply px-4;
	@apply md:px-0;
	@apply flex;
	@apply flex-col;
	@apply items-center;

	@screen xl {
		max-width: 1225px;
	}
}

.row {
	@apply md:-mx-7;
	@apply flex;
	@apply flex-wrap;
	@apply w-full;
}

.col {
	@apply md:px-7;
}

.redactor {
	&.footer {
		@apply text-base;
		@apply text-sand;
		@apply font-silka-regular;

		h3 {
			@apply font-silka-semibold;
			@apply font-normal;
			@apply text-white;
			@apply mb-2;
		}

		p {
			@apply mb-2;

			&:last-child {
				@apply mb-0;
			}
		}

		a {
			&:hover {
				@apply underline;
			}
		}
	}
}

// WBC
.bg-wbc {
	background: linear-gradient(89.89deg, #ea865b -18.18%, #91b7fa 49.96%, #cca6eb 116.7%);
}

.claim {
	background: linear-gradient(89.89deg, #ea865b -18.18%, #91b7fa 49.96%, #cca6eb 116.7%);
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

// Firefox only styling
@supports (-moz-appearance: none) {
	.job-page .claim {
		@apply opacity-40;
	}
}

.content-contact-page + footer {
	@apply p-0;
}

// Focus for all interactive elements
button,
a,
input,
textarea,
.preview-video {
	&:focus {
		outline-color: theme("colors.focusColor");
		outline-offset: 0.125rem;
		outline-style: solid;
		outline-width: 0.125rem;

		&:not(&:focus-visible) {
			outline: 0;
		}
	}

	&:focus-visible {
		outline-color: theme("colors.focusColor");
		outline-offset: 0.125rem;
		outline-style: solid;
		outline-width: 0.125rem;
	}
}

.menu-item-link {
	&:focus {
		.origin-text {
			outline-color: theme("colors.focusColor");
			outline-offset: 0.125rem;
			outline-style: solid;
			outline-width: 0.125rem;
		}

		&:not(&:focus-visible) {
			.origin-text {
				outline: 0;
			}
		}
	}

	&:focus-visible {
		.origin-text {
			outline-color: theme("colors.focusColor");
			outline-offset: 0.125rem;
			outline-style: solid;
			outline-width: 0.125rem;
		}
	}
}
