@font-face {
	font-family: "silka-regular";
	font-display: swap;
	src: url("/src/fonts/silka-regular-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "silka-semibold";
	font-display: swap;
	src: url("/src/fonts/Silka-SemiBold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "esrebond-regular";
	font-display: swap;
	src: url("/src/fonts/ESRebondGrotesque-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "esrebond-bold";
	font-display: swap;
	src: url("/src/fonts/ESRebondGrotesque-Bold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "esrebond-medium";
	font-display: swap;
	src: url("/src/fonts/ESRebondGrotesque-Medium.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
