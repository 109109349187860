footer {
	.menu-item {
		@apply text-base;
		
		&.active-tab {
			@apply underline;
		}

		&:hover {
			@apply underline;
		}
	}

	.menu-item-footer {
		&.active-tab {
			@apply underline;
		}

		&:hover {
			@apply underline;
		}

		a,
		&.copyright {
			@apply mr-2;
			@apply relative;
			@apply flex;
			@apply justify-center;
			@apply border-r;
			@apply border-grey-500;

			&::after {
				content: "";
				@apply inline-block;
				@apply h-0.5;
				@apply bg-grey-500;
				@apply ml-2;
				@apply mt-1;
			}
		}

		&:last-child {
			a {
				@apply mr-0;
				@apply border-none;

				&::after {
					@apply hidden;
				}
			}
		}
	}
}
