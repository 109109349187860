.button {
	@apply cursor-pointer;
	@apply no-underline;

	&.arrow {
		@apply text-button-jobs;
		@apply uppercase;
	}

	.arrow-container {
		transition: background-color 0.3s ease;
	}
}

